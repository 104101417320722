import type { FC } from 'react'

import type { ExtendBlocksType } from '@Types/blocks/blocks'
import type { SingleImageType } from '@Types/blocks/singleImage'

import { SingleImageContainer, StyledImage } from './styles'

type Props = {
  data: ExtendBlocksType
}

export const SingleImage: FC<Props> = ({ data }) => {
  const { svg_image } = data as SingleImageType

  return (
    <SingleImageContainer>
      <StyledImage alt={data.info} src={svg_image.uri.url} />
    </SingleImageContainer>
  )
}
